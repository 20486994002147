<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Training Requests</h1>
              </div>
              <div class="row">
                <div class="col-md-8 d-flex justify-start">
                  <!-- <div class="row">
                    <div class="col-md-4">
                      <TextField type="date" :label="'From'" />
                    </div>
                    <div class="col-md-4">
                     <TextField type="date" :label="'to'" />
                    </div>
                  </div> -->
                </div>
                <div class="col-md-4">
                  <div class="export-container">
                    <SelectField :items="downloadFormat" v-model="formData.selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                    <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Export'" @onClick="onDownload" class="mr-2"/>
                  </div>
                </div>
              </div>
              <v-data-table
                :headers= 'headers'
                :items="trainingRecords"
              >
              </v-data-table>
              <div v-if="loadingData" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
              </div>
              <table hidden id="trainingTable">
                <thead>
                  <tr>
                    <th>Personnel Name</th>
                    <th>Training Name</th>
                    <th>Amount</th>
                    <th>Start Date</th>
                    <th>Resumption Date</th>
                    <th>No. of Days</th>
                    <th>Reqeust Date</th>
                    <th>Approved</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="training in trainingRecords" :key="training.requestId">
                    <td>
                      {{training.personnelName}}
                    </td>
                    <td>
                      {{training.name}}
                    </td>
                    <td>
                      {{training.amount}}
                    </td>
                    <td>
                      {{training.startDate}}
                    </td>
                    <td>
                      {{training.endDate}}
                    </td>
                    <td>
                      {{training.days}}
                    </td>
                    <td>
                      {{training.date}}
                    </td>
                    <td>
                      {{training.status}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { requestService } from '@/services'
import ExportToCSVFile from '@/utils/helper'
import moment from 'moment'
import XLSX from 'xlsx'
import JSPDF from 'jspdf'
import 'jspdf-autotable'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    SelectField,
    Button,
    Dialog
    // TextField
  },
  data () {
    return {
      loadingData: false,
      isDownloading: false,
      search: '',
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      trainingRecords: [],
      headers: [
        {
          text: 'Personnel Name',
          value: 'personnelName'
        },
        {
          text: 'Training Name',
          value: 'name'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'Resumption Date',
          value: 'endDate'
        },
        {
          text: 'No. of Days',
          value: 'days'
        },
        {
          text: 'Request Date',
          value: 'date'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      downloadFormat: [
        {
          name: 'Excel',
          id: 'excel'
        },
        {
          name: 'PDF',
          id: 'pdf'
        },
        {
          name: 'CSV',
          id: 'csv'
        }
      ],
      selectedFormat: ''
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      // this.$router.push('/medical/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteJobtitle () {
      // this.$store.dispatch(DELETE_JOBTITLE, this.formData).then((result) => {
      //   this.showAlertMessage('Jobtitle deleted successfully', 'success')
      //   // TODO remove this console.log
      // }).catch(error => {
      //   console.log(error.message)
      //   // TODO please put an alert component here
      // })
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    onSelectedFormat (value) {
      this.selectedFormat = value.id
    },
    onDownload () {
      if (this.selectedFormat !== '') {
        this.isDownloading = true
        const time = moment().format()
        switch (this.selectedFormat) {
          case 'pdf':
            const doc = new JSPDF('landscape')
            doc.autoTable({
              theme: 'striped',
              headStyles: { fillColor: '#0B2D71', textColor: '#fff' },
              alternateRowStyles: { fillColor: '#F3F4F8' },
              html: '#trainingTable'
            })

            doc.text('Approved Training - CMMS', 150, 10, { align: 'center' })
            // doc.text('L - Leave', 150, 200, { align: 'center' })
            doc.save(`ApprovedTraining_${time}.pdf`)
            this.isDownloading = false
            break
          case 'excel':
            var trainingSheet = XLSX.utils.json_to_sheet(this.trainingRecords)
            var trainingWorkBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(trainingWorkBook, trainingSheet, 'ApprovedTraining')
            XLSX.writeFile(trainingWorkBook, `ApprovedTraining_${time}.xlsx`)
            this.isDownloading = false
            break
          case 'csv':
            const formattedData = []
            const fileHeaders = {
              personnelName: 'Personnel Name',
              trainingName: 'Training Name',
              amount: 'Amount',
              startDate: 'Start Date',
              resumptionDate: 'Resumption Date',
              days: 'No of Days',
              requestDate: 'Request Date',
              status: 'Status'
            }
            this.trainingRecords.forEach(item => {
              formattedData.push({
                personnelName: item.personnelName.replace(/,/g, ''),
                name: item.name.replace(/,/g, ''),
                amount: item.amount.replace(/,/g, ''),
                startDate: item.startDate.replace(/,/g, ''),
                endDate: item.endDate,
                days: item.days,
                date: item.date,
                status: 'Approved'
              })
            })

            ExportToCSVFile(fileHeaders, formattedData, `ApprovedTraining_${time}`)
            this.isDownloading = false
            break
          default:
            break
        }
      }
    },
    formatAmount (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  },
  mounted () {
    this.loadingData = true
    requestService.getApprovedTraining().then(result => {
      const data = result.data
      const records = []
      data.forEach(item => {
        const request = item.request
        const employee = item.request.employee
        const requestType = item.request.requestType
        records.push({
          personnelName: `${employee.firstName}, ${employee.lastName}`,
          name: requestType.name,
          amount: this.formatAmount(item.amount),
          startDate: moment(request.startDate).format('DD-MMM-yyyy'),
          endDate: moment(request.resumptionDate).format('DD-MMM-yyyy'),
          days: request.leaveDays,
          date: moment(request.dateAdded).format('DD-MMM-yyyy'),
          status: 'Approved'
        })
      })
      this.trainingRecords = records
    }).catch((error) => {
      this.alertMessage(error.message, 'error')
    }).finally(() => {
      this.loadingData = false
    })
  }
}
</script>
<style scoped>
.export-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
