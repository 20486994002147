const convertToCSV = (objectArray) => {
  const JsonData = typeof objectArray !== 'object' ? JSON.parse(objectArray) : objectArray
  let csvString = ''

  for (var i = 0; i < JsonData.length; i++) {
    let line = ''
    for (var index in JsonData[i]) {
      if (line !== '') line += ','

      line += JsonData[i][index]
    }

    csvString += line + '\r\n'
  }

  return csvString
}

const ExportToCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers)

    const jsonObject = JSON.stringify(items)
    const csvData = convertToCSV(jsonObject)
    const exportedFilename = `${fileTitle}.csv`
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
}

export default ExportToCSVFile
